* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.dufferin,
.john,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.dufferin {
  background-image: url('../public/images/DufferinFront.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: black;
  -webkit-text-stroke: 2px black;
  color: #fff;
  font-size: 100px;
}

.john {
  background-image: url('../public/images/JohnStreetFront.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: black;
  -webkit-text-stroke: 2px black;
  color: #fff;
  font-size: 100px;
}

